<template>
    <div class='out_storage'>
        <a-modal 
            :title="title" 
            :confirm-loading="confirmLoading" 
            @ok="confirm"
            v-model:visible="showDialog">
                <m-dialog-content  
                :rules="rules"
                :forms="forms"
                :readOnly="!isEdit"
                :formItems="truePopItem">
                </m-dialog-content>
            </a-modal>
        <m-form
            :formItems="formItems"
            @loadData="loadData(1)"></m-form>
        <m-table 
            :buttons="buttons" 
            :loading="loading" 
            :columns="columns" 
            :data="tableList" 
            :totals="total"
            :currentPage="currentPage"
            @check="detail($event, 'check')"
            @pageChange="loadData($event)"
            @detail="detail($event)"></m-table>
    </div>
</template>
<script>
import { computed, reactive, toRefs } from 'vue'
import { isValid, agentWithdrawList, agentWithdrawDetail, agentWithdrawCheck } from '../../utils/api'
import { assginDataToArray, geneIndex } from '@/utils/util'
import { message } from 'ant-design-vue'
export default {
    name: '',
    setup() {
         const state= reactive({
             title: '详情',
             confirmLoading: false,
             showDialog: false,
             buttons: [
                 {text: '审核', eventName: 'check'},
                 {text: '详情', eventName: 'detail'}
             ],
             deliverer: {value: '', options: []},
             columns: [
                 {title: '序号', dataIndex: 'index', key: 'index'},
                 {title: 'ID', dataIndex: 'logId', key: 'logId'},
                 {title: '提现金额（元）', dataIndex: 'amount', key: 'amount'},
                 {title: '提现手续费（元）', dataIndex: 'fee', key: 'fee'},
                 {title: '提现银行', dataIndex: 'bankName', key: 'bankName'},
                 {title: '提现银行卡号', dataIndex: 'bankCardNo', key: 'bankCardNo'},
                 {title: '提现支行', dataIndex: 'bankSubName', key: 'bankSubName'},
                 {title: '提现状态', dataIndex: 'statusText', key: 'statusText'},
                 {title: '提现时间', dataIndex: 'applyTime', key: 'applyTime'},
                 {title: '操作', dataIndex: 'manage', key: 'manage', slots: {customRender: 'actions'}}
             ],
             formItems: [
                 {type: 'selector', label: '状态', key: 'value', options: [{label: '待操作', value: 0}, {label: '处理中', value: 1}, {label: '提现成功', value: 2}, {label: '提现失败', value: 3}], value: undefined},
                 {type: 'button', eventName: 'loadData', label: '搜索'},
             ],
             popForms: [
                 {label: '持卡人', prop: 'bankCardHolder', value: '', type: 'input'},
                 {label: '提现金额（元）', prop: 'amount', value: '', type: 'input'},
                 {label: '提现手续费（元）', prop: 'fee', value: '', type: 'input'},
                 {label: '收款人银行卡号', prop: 'bankCardNo', value: '', type: 'input'},
                 {label: '收款人银行名称', prop: 'bankName', value: '', type: 'input'},
                 {label: '收款人支行名称', prop: 'bankSubName', value: '', type: 'input'},
                 {label: '提现时间', prop: 'applyTime', value: '', type: 'input'},
                 {label: '到账时间', prop: 'successTime', value: '', type: 'input'},
                 {label: '单据状态', prop: 'statusText', value: '', type: 'input'}
            ],
            editForms: [
                 {label: '状态', value: '', prop: 'status', type: 'radio', options: [ 
                    {label: '待操作', value: 0},
                    {label: '处理中', value: 1},
                    {label: '提现成功', value: 2},
                    {label: '提现失败', value: 3}
                    ],},
                {label: '银行流水号', value: '', prop: 'tradeNo', type: 'input'},
                {label: '备注', value: '', prop: 'remark', type: 'input'}
            ],
             forms: {timeout: ''},
             rules: {timeout: [{ required: true, message: '请输入超时时间', trigger: 'blur'}]},
             tableList: [],
             currentPage: 1,
             pageSize: 10,
             total: 0,
             loading: false,
             status: 0,
             logId: '',
             isEdit: false
        })
        const truePopItem = computed(() => {
            return state.isEdit ? state.editForms : state.popForms
        })
        function initStatusText(data) {
            data.forEach(ele => {
                const s = ele.status 
                ele.statusText = s == 0 ? '待操作'
                                :s == 1 ? '处理中'
                                :s == 2 ? '提现成功'
                                :s == 3 ? '提现失败' : '未知状态'
            })
            return data
        }
        async function  loadData(pageNum = null) {
            try {
                state.loading = true
                state.currentPage = pageNum || state.currentPage
                const item = state.formItems
                const params = {
                    pageNum: state.currentPage,
                    pageSize: state.pageSize,
                    // logId: item[0].value,
                    // name: item[1].value,
                    status: item[0].value
                }
                const result = await agentWithdrawList(params)
                state.loading = false
                if(isValid(result)) {
                    state.total = result.data.total
                    state.tableList = geneIndex(result.data.logList, state.pageSize, state.currentPage)
                    state.tableList = initStatusText(state.tableList)
                }
            } catch(e) {
                state.loading = false
                console.error(e)
            }
        }
        loadData()
        async function detail(event, type="detail") {
            try {
                console.log(event, type)
                state.logId = event.logId
                state.status = event.status
                const result = await agentWithdrawDetail(event.logId)
                if (isValid(result)) {
                    console.log(result.data)
                    const s = result.data.status
                    result.data.statusText = s == 0 ? '待处理' 
                                           : s == 1 ? '处理中'
                                           : s == 2 ? '提现成功'
                                           : s == 3 ? '提现失败' : s
                    if (type == 'detail') {
                        state.popForms = assginDataToArray(result.data, state.popForms)
                    } else if (type == 'check') {
                        state.editForms = assginDataToArray(result.data, state.editForms)
                    }
                    state.isEdit = type == 'check'
                    state.showDialog = true
                }
            } catch(e) {
                console.error(e)
            }
        }
        function closeDialog() {
            loadData()
            state.showDialog = false
        }
        async function confirm() {
            try {
                if (state.isEdit) {
                    const items = truePopItem.value
                    const params = {
                        logId: state.logId,
                        status: items[0].value,
                        tradeNo: items[1].value,
                        remark: items[2].value
                    }
                    const result = await agentWithdrawCheck(params)
                    if (result.code == 0) {
                        loadData()
                        message.success('处理成功')
                        state.showDialog = false
                    }
                } else {
                    state.showDialog = false
                }
            } catch(e) {
                console.error(e)
            }
        }
        return {
            ...toRefs(state),
            detail,
            loadData,
            closeDialog,
            confirm,
            truePopItem
        }
    },
}
</script>
<style lang='scss' scoped>
</style>